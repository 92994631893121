import Banner from '../components/shared/Banner/Banner';
import SectionExchange from '../components-for-pages/main/SectionExchange';
import SectionInfo from '../components-for-pages/main/SectionInfo';
import SectionPluses from '../components-for-pages/main/SectionPluses';
import SectionTable from '../components-for-pages/SectionTable/SectionTable';
import SectionActions from '../components-for-pages/main/SectionActions';
import SectionNews from '../components-for-pages/SectionNews/SectionNews';
import { useOutletContext } from 'react-router-dom';
import { IOutletContext } from '../layouts/Layout';

function MainPage() {
  const { onOpenModalRequest } = useOutletContext<IOutletContext>();

  return (
    <>
      <Banner />
      <SectionTable
        title="Курс AG&ndash;Trade"
        subtitle="Покупка и продажа (Bitcoin, Ethereum, Toncoin и других) осуществляется по вашему запросу. Все операции обмена проводятся исключительно в кассе."
      />
      <SectionExchange onButtonClick={onOpenModalRequest} />
      <SectionInfo />
      <SectionPluses />
      <SectionActions />
      <SectionNews />
    </>
  );
}

export default MainPage;
