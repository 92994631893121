import { useEffect, useState } from 'react';
import SectionWrapper from '../../components/shared/SectionWrapper/SectionWrapper';
import TableCourses from '../../components/tables/TableCourses';
import fetchData, { IFetchData } from '../../helpers/fetchData';
// import { data_table_crypto, data_table_main } from "../../texts";

import "./SectionTable.scss";

const tableHeaderItems = ["", "Покупка", "Продажа", "Объем"];

interface IProps {
  title?: string;
  subtitle?: string;
  isCrypto?: boolean;
}

export function SectionTable({ title, subtitle, isCrypto }: IProps) {
  const [data, setData] = useState<IFetchData | undefined>(undefined);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchData();
        setData(result);
      } catch (err) {
        console.log('Ошибка при подгрузке данных...')
      }
    };

    loadData();
  }, []);

  if (data === undefined) return <SectionWrapper background="transparent">Ошибка загрузки данных таблицы.</SectionWrapper>

  return (
    <SectionWrapper background="transparent">
      {title && <h2 className="heading-1 SectionTable-title">{title}</h2>}

      <TableCourses headerItems={tableHeaderItems} rows={isCrypto ? data.data_table_crypto : data.data_table_main} />

      {subtitle && <p className="text-mini SectionTable-text">{subtitle}</p>}
    </SectionWrapper>
  );
}

export default SectionTable;
