import { ReactNode } from 'react';
import Container from '../Container/Container';
import "./SectionWrapper.scss";

interface IProps {
  children?: ReactNode | string;
  background: "light" | "shadow" | "dark" | "transparent";
}

export function SectionWrapper({ children, background }: IProps) {
  const classNames = `SectionWrapper SectionWrapper--${background}`;

  return (
    <section className={classNames}>
      <Container>
        {children}
      </Container>
    </section>
  );
}

export default SectionWrapper;
