import "./CardCourse.scss";

interface IProps {
  price: string;
  text: string;
}

export function CardCourse({ price, text }: IProps) {
  return (
    <div className="CardCourse">
      <p className="heading-mini">{price}</p>
      <p className="text-1">{text}</p>
    </div>
  );
}

export default CardCourse;
