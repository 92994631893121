/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useRef } from 'react';
import { IconClose } from '../../../icons/IconClose';
import "./ModalWrapper.scss";

interface IProps {
  children: ReactNode;
  maxWidth?: string;
  onClose: VoidFunction;
}

export function ModalWrapper({ children, maxWidth, onClose }: IProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  const onClickOutside = (event: MouseEvent) => {
    if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, []);

  return (
    <div className="ModalWrapperСontainer">
      <div className="ModalWrapper" style={{ maxWidth: maxWidth }} ref={ref}>
        <div className="ModalWrapper-header">
          <h4 className="ModalWrapper-title heading-mini">Заказать обмен</h4>
          <button onClick={onClose} type="button" className="ModalWrapper-button-close">
            <IconClose />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
}

export default ModalWrapper;
