import Banner from '../components/shared/Banner/Banner';
import SectionPluses from '../components-for-pages/main/SectionPluses';
import SectionTable from '../components-for-pages/SectionTable/SectionTable';

function CryptoPage() {
  return (
    <>
      <Banner customTitle="Криптовалюта" />
      <SectionTable
        isCrypto={true}
        title="Курс на криптовалюты от AG&ndash;Trade"
        subtitle="Покупка и продажа (Bitcoin, Ethereum, Toncoin и других) осуществляется по вашему запросу. Все операции обмена проводятся исключительно в кассе."
      />
      <SectionPluses />
    </>
  );
}

export default CryptoPage;
