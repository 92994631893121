import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Layout from "./layouts/Layout";
import MainPage from './pages/MainPage';
import CryptoPage from './pages/CryptoPage';
import NewsPage from './pages/NewsPage';
import ContactsPage from './pages/ContactsPage';
import ChangePage from './pages/ChangePage';
import News1Page from './pages/News1';

export const authorizedRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/" element={<MainPage />} />
      <Route path="/crypto" element={<CryptoPage />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/change" element={<ChangePage />} />
      <Route path="/news/*" element={<News1Page />} />
    </Route>
  )
);
