import { Outlet } from 'react-router-dom';
import Header from './blocks/Header';
import Footer from '../components/shared/Footer/Footer';
import srcImageBackground from '../images/bg.png';
import ScrollToTop from '../helpers/ScrollToTop';
import { useState } from 'react';
import ModalRequest from '../components/modals/ModalRequest/ModalRequest';

import "./Layout.scss";

export interface IOutletContext {
  onOpenModalRequest: VoidFunction,
  onCloseModalRequest: VoidFunction,
  isVisibleModalRequest: boolean,
}

interface IProps {
  onHeaderButtonClick?: VoidFunction;
};

function Layout({ onHeaderButtonClick }: IProps) {
  const [isVisibleModal, setVisibleModal] = useState(false);
  const onOpenModal = () => setVisibleModal(true);
  const onCloseModal = () => setVisibleModal(false);

  const outletContextData: IOutletContext = {
    onOpenModalRequest: onOpenModal,
    onCloseModalRequest: onCloseModal,
    isVisibleModalRequest: isVisibleModal,
  }

  return (
    <div className="Layout">
      {isVisibleModal && <ModalRequest onClose={onCloseModal} />}

      <ScrollToTop />

      <div>
        <Header onButtonClick={onOpenModal} />
        <section className="Layout-section-background">
          <img src={srcImageBackground} alt="Баннер" className="Layout-background" />
        </section>

        <Outlet context={outletContextData} />

      </div>
      <Footer />
    </div>
  );
}

export default Layout;
