import { ReactNode } from 'react';

import "./ButtonAccent.scss";

interface IProps {
  children: ReactNode | string;
  type?: "button" | "submit";
  onClick?: VoidFunction;
  disabled?: boolean;
}

export function ButtonAccent(props: IProps) {
  const { children, type, onClick, disabled } = props;

  return (
    <button disabled={disabled} className="ButtonAccent" type={type} onClick={onClick}>
      {children}
    </button>
  );
}

export default ButtonAccent;
