import { useLocation } from 'react-router-dom';
import NewsPageTemplate from './NewsPageTemplate';
import { useEffect, useState } from 'react';
import { news } from '../data/news';

function News1Page() {
  let location = useLocation();
  const [pageNumber, setPageNumber] = useState<number | undefined>(undefined);
  const currentNewsItem = news.find(item => item.id === pageNumber);

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    const numberNews = Number(pathArray[pathArray.length - 1]);
    setPageNumber(numberNews);
  }, [location]);

  if (currentNewsItem) return <NewsPageTemplate
    imageSrc={currentNewsItem.image_src}
    title={currentNewsItem.full_title}
    text={currentNewsItem.full_description}
  />;

  return <NewsPageTemplate
    title="Новость не найдена"
    text="Новость не найдена"
  />;
}

export default News1Page;
