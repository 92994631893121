import Container from '../Container/Container';
import NavItems from '../NavItems/NavItems';
import "./Footer.scss";

export function Footer() {
  return (
    <footer className="Footer">
      <Container>
        <div className="Footer-columns-wrapper">
          <nav className="Footer-nav">
            <NavItems withContacts />
          </nav>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
