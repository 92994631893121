import imageSrc1 from '../images/news1.jpg';
import imageSrc2 from '../images/news1.jpg';
import imageSrc3 from '../images/news3.jpg';
import imageSrc4 from '../images/news1.jpg';

export const news = [
  {
    id: 1,
    image_src: imageSrc1,
    full_title: "Арендные ставки в России достигли исторического максимума в 3 квартале 2024 года",
    mini_title: "Арендные ставки в России достигли исторического...",
    mini_description: "Согласно данным ДОМ.РФ, стоимость аренды жилья по всей стране увеличивается двузначными...",
    full_description: <div>
      Согласно данным ДОМ.РФ, стоимость аренды жилья по всей стране увеличивается двузначными темпами. Средние показатели цен:
      <ul>
        <li>Москва – 111 тыс. рублей</li>
        <li>Санкт-Петербург – 56 тыс. рублей</li>
        <li>Не столичные города – 43 тыс. рублей</li>
      </ul>
      Рост цен на аренду вызван переходом россиян на съемное жилье вместо ипотеки, что связано с отменой льготных программ и высокой ключевой ставкой.
    </div>
  },
  {
    id: 2,
    image_src: imageSrc2,
    full_title: "Рынок облигаций демонстрирует худшие показатели",
    mini_title: "Рынок облигаций демонстрирует худшие показатели...",
    mini_description: "Согласно свежим данным Росстата, инфляция в октябре продолжила ускорение, что усиливает...",
    full_description: <div>
      <ul>
        <li>Индекс RGBI достиг исторического минимума</li>
        <li>Индекс корпоративных облигаций также обновил рекордно низкий уровень</li>
      </ul>
      Согласно свежим данным Росстата, инфляция в октябре продолжила ускорение, что усиливает вероятность повышения ключевой ставки Банком России до 23% на предстоящем заседании.
    </div>
  },
  {
    id: 3,
    image_src: imageSrc3,
    full_title: "ЦБ России приобрел Санкт-Петербургскую валютную биржу (СПВБ) для поддержки валютных торгов",
    mini_title: "ЦБ России приобрел Санкт-Петербургскую валютную...",
    mini_description: "Планируется, что СПВБ станет полноценной площадкой для торговли иностранными валютами...",
    full_description: <div>
      <p>
        Планируется, что СПВБ станет полноценной площадкой для торговли иностранными валютами, включая доллар и евро. На данный момент здесь уже проходят торги дружественными валютами, но для расширения функционала потребуется значительная работа.
      </p>
      <p>
        Несмотря на планы, некоторые участники рынка скептически относятся к проекту, полагая, что при росте объемов торгов биржа рискует попасть под международные санкции.
      </p>
    </div>
  },
  {
    id: 4,
    image_src: imageSrc4,
    full_title: "Банк России может повысить ключевую ставку до 23%, если тренд на усиление инфляционных ожиданий сохранится – Промсвязьбанк",
    mini_title: "Банк России может повысить ключевую ставку до...",
    mini_description: "Инфляция за прошедшую неделю ускорилась с 0,20% до 0,27%, а годовая инфляция ускорилась...",
    full_description: <div>
      <p>
        Инфляция за прошедшую неделю ускорилась с 0,20% до 0,27%, а годовая инфляция ускорилась с 8,46% до 8,57%. Рост цен с начала года составил 6,55%.
      </p>
    </div>
  }
];