import SectionWrapper from '../../components/shared/SectionWrapper/SectionWrapper';
import srcBg from '../../images/bg2.png';
import "./SectionInfo.scss";

export function SectionInfo() {
  return (
    <SectionWrapper background="transparent">
      <div className="SectionInfo-wrapper-content">
        <img src={srcBg} alt='Баннер' />
        <div className="SectionInfo-content">
          <h4 className="heading-1 SectionInfo-title">Уже 12&nbsp;лет мы&nbsp;поддерживаем высокий уровень обслуживания:</h4>
          <p className="text-1">1. Международные перестановки по всему миру</p>
          <p className="text-1">2. Оплата инвойс платежей/SWIFT</p>
          <p className="text-1">3. Покупка и продажа USDT</p>
        </div>
      </div>
    </SectionWrapper>
  );
}

export default SectionInfo;
