import "./TableCourses.scss";

export type TItem = {
  currency: string;
  buyRate: number;
  sellRate: number;
  minAmount: number;
}

interface IProps {
  headerItems: string[];
  rows: TItem[];
}

export function TableCourses({ headerItems, rows }: IProps) {
  return (
    <div className="TableCourses-table">
      <div className="TableCourses-row">
        {headerItems.map(item => (
          <h5 key={item} className="TableCourses-title heading-mini">{item}</h5>
        ))}
      </div>
      {rows.map(row => {
        return (
          <div key={row.buyRate + row.minAmount + row.sellRate} className="TableCourses-row">
            <p className="text-mini">{row.currency}</p>
            <p className="text-mini">{row.buyRate}</p>
            <p className="text-mini">{row.sellRate}</p>
            <p className="text-mini">от {row.minAmount}</p>
          </div>
        )
      })}
    </div>
  );
}

export default TableCourses;
