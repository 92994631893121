import emailjs from '@emailjs/browser';
import ButtonAccent from '../../components/shared/ButtonAccent/ButtonAccent';
import Input, { InputPhone } from '../../components/shared/Input/Input';
import SectionWrapper from '../../components/shared/SectionWrapper/SectionWrapper';
import { useRef, useState } from 'react';

import "./SectionContacts.scss";



export function SectionContacts() {
  const form = useRef(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!form.current) return;

    emailjs.sendForm('service_ag_trade', 'template_5r2mp28', form.current, {
      publicKey: 'uWrvoY8ZWXv7j-g9-',
    }).then(
      () => {
        setSuccessMessage("Заявка принята!");
      },
      (error) => {
        setErrorMessage("Ошибка при отправке формы");
      },
    );
  }

  return (
    <SectionWrapper background="light">
      <div className="SectionContacts-card">
        <div className="SectionContacts-card-item">
          <p>Телефон</p>
          <a href="tel:+7 (999) 335-80-00" className="heading-mini">+7 (999) 335-80-00</a>
        </div>

        <div className="SectionContacts-card-item">
          <p>E-mail</p>
          <a href="mailto:ag-trade8@mail.ru" className="heading-mini">ag-trade8@mail.ru</a>
        </div>

        <div className="SectionContacts-card-item">
          <p>Телеграм</p>
          <a href="https://t.me/obmen_exchange_moscow" target="_blank" rel="noreferrer" className="heading-mini">t.me/obmen_exchange_moscow</a>
        </div>
      </div>

      <div className="SectionContacts-card SectionContacts-card--form">
        <h3 className="heading-mini" style={{ display: "block" }}>Обращайтесь к нам, если остались дополнительные вопросы или хотите оставить заявку</h3>
        <form ref={form} onSubmit={onSubmit}>
          <Input name="user_name" type="text" placeholder="Ваше имя" />
          <InputPhone name="user_phone" />
          <ButtonAccent type="submit">Оставить заявку на улсугу</ButtonAccent>
          <p>Нажимая на кнопку, я соглашаюсь с политикой конфиденциальности</p>
          {successMessage && <p className="SectionContacts-success">{successMessage}</p>}
          {errorMessage && <p className="SectionContacts-error">{errorMessage}</p>}
        </form>
      </div>
    </SectionWrapper>
  );
}

export default SectionContacts;
