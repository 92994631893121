import { ReactNode } from 'react';

import "./Container.scss";

function Container(props: { children: ReactNode }) {
  const { children } = props;
  return (
    <div className="Container">
      {children}
    </div>
  );
}

export default Container;
