
import SectionWrapper from '../../components/shared/SectionWrapper/SectionWrapper';
import CardNews from '../../components/cards/CardNews';
import { news } from '../../data/news';
import "./SectionNews.scss";


export function SectionNews() {
  return (
    <SectionWrapper background="light">
      <h2 className="heading-1">Последние новости</h2>
      <div className="SectionNews-cards">
        {news.map(card => (
          // <div key={card.text} className="SectionNews-card">
          //   <h4 className="heading-mini SectionNews-card-heading">{card.title}</h4>
          //   <p className="text-1">{card.text}</p>
          //   <Link className="SectionNews-card-link" to="/news/1">Подробнее</Link>
          // </div>
          <CardNews title={card.mini_title} content={card.mini_description} link={`/news/${card.id}`} />
        ))}
      </div>
    </SectionWrapper>
  );
}

export default SectionNews;
