
import { useEffect, useState } from 'react';
import CardCourse from '../../components/cards/CardCourse';
import ButtonAccent from '../../components/shared/ButtonAccent/ButtonAccent';
import SectionWrapper from '../../components/shared/SectionWrapper/SectionWrapper';
import fetchData, { IFetchData } from '../../helpers/fetchData';

import "./SectionExchange.scss";

export function SectionExchange({ onButtonClick }: { onButtonClick: VoidFunction }) {
  const [data, setData] = useState<IFetchData | undefined>(undefined);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchData();
        setData(result);
      } catch (err) {
        console.log('Ошибка при подгрузке данных...')
      }
    };

    loadData();
  }, []);

  if (data === undefined) return <SectionWrapper background="transparent">Ошибка загрузки данных таблицы.</SectionWrapper>

  return (
    <SectionWrapper background="light">
      <div>
        <h4 className="heading-1 SectionExchange-title">Биржевой курс</h4>
        <div className="SectionExchange-cards">
          {data.data_cards_main.map(item => (
            <CardCourse key={item.name} price={item.price} text={item.name} />
          ))}
        </div>
      </div>
      <div className="SectionExchange-button-wrapper">
        <ButtonAccent onClick={onButtonClick}>Заказать обмен</ButtonAccent>
      </div>
    </SectionWrapper>
  );
}

export default SectionExchange;
