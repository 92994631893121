
import { RouterProvider } from 'react-router-dom';
import { authorizedRouter } from './router';

import './AppShared.scss';
import './App.scss';

function App() {
  return (
    <div className="App">
      <RouterProvider router={authorizedRouter} />
    </div>
  );
}

export default App;
