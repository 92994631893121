/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import ButtonAccent from '../../components/shared/ButtonAccent/ButtonAccent';
import NavItems from '../../components/shared/NavItems/NavItems';
import { IconBurger } from '../../icons/IconBurger';
import { IconClose } from '../../icons/IconClose';
import { useLocation } from 'react-router-dom';

import "./Header.scss";

interface IProps {
  onButtonClick: VoidFunction;
}

export function Header({ onButtonClick }: IProps) {
  const [isActiveBurger, setActiveBurger] = useState(false);

  const onToggleMenu = () => {
    setActiveBurger(prev => !prev);
  };

  const onClose = () => {
    setActiveBurger(false);
  };

  return (
    <>
      <HeaderMobileNav onClose={onClose} isActive={isActiveBurger} />
      <header className="Header">
        <div className="Header-container">

          <div className="Header-content">
            <button className={`Header-burger-button ${isActiveBurger && "active"}`} onClick={onToggleMenu}>
              <IconBurger />
            </button>
            <nav className="Header-nav">
              <NavItems />
            </nav>
            <ButtonAccent onClick={onButtonClick}>Консультация</ButtonAccent>
          </div>
        </div>
      </header>
    </>
  );
}

const HeaderMobileNav = ({ onClose, isActive }: { onClose: VoidFunction, isActive: boolean }) => {
  const { pathname } = useLocation();

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    onClose();
  }, [pathname]);

  const onClickOutside = (event: MouseEvent) => {
    if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, []);



  return (
    <nav className="HeaderMobileNav" ref={ref} style={isActive ? undefined : { transform: "translateX(-200%)" }} >
      <div className="HeaderMobileNav-close-button" onClick={onClose}>
        <IconClose />
      </div>
      <NavItems />
    </nav >
  );
};

export default Header;
