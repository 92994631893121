/* eslint-disable no-self-compare */
import { useState } from 'react';
import { InputMask, MaskEventHandler, Modify } from '@react-input/mask';

import "./Input.scss";

interface IProps {
  type: "number" | "text" | "tel";
  placeholder?: string;
  name: string;
}

export function Input(props: IProps) {
  const { type, placeholder, name } = props;

  const [value, setValue] = useState('');

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);

    if (newValue > -1 && newValue <= 9999999) setValue(newValue.toString());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number") handleChangeNumber(e);
    else setValue(e.target.value);
  }

  return (
    <input name={name} placeholder={placeholder} onChange={handleChange} value={value} className="Input" type={type} max="9999999" />
  );
}

interface IPropsPhone {
  handleMask?: MaskEventHandler;
  name: string;
}

export function InputPhone(props: IPropsPhone) {
  const { handleMask, name } = props;

  const modify: Modify = (input) => {
    if (input.length > 0) return { showMask: true }

    return { showMask: false };
  };

  return (
    <InputMask
      name={name}
      type="tel"
      placeholder="Номер телефона"
      mask="+7 (___) ___ __-__"
      replacement={{ "_": /^\d+$/, }}
      modify={modify}
      onMask={handleMask}
      className="Input"
    />
  )
}

export default Input;
