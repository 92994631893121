import SectionNews from '../components-for-pages/SectionNews/SectionNews';
import Banner from '../components/shared/Banner/Banner';
import Container from '../components/shared/Container/Container';

function NewsPage() {
  return (
    <>
      <Banner customTitle=" " />
      <SectionNews />
    </>
  );
}

export default NewsPage;
