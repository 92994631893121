import { ReactNode } from 'react';
import "./CardNews.scss";
import { Link } from 'react-router-dom';

interface IProps {
  title: string | ReactNode;
  content: string | ReactNode;
  link: string;
}

export function CardNews({ title, content, link }: IProps) {
  return (
    <div className="CardNews">
      <p className="heading-mini">{title}</p>
      <p className="text-1">{content}</p>
      <Link to={link}>Подробнее</Link>
    </div>
  );
}

export default CardNews;
