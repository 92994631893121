import SectionContacts from '../components-for-pages/SectionContacts/SectionContacts';
import Banner from '../components/shared/Banner/Banner';

function ContactsPage() {
  return (
    <>
      <Banner customTitle="Контакты" />
      <SectionContacts />
    </>
  );
}

export default ContactsPage;
