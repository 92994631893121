import SectionWrapper from '../../components/shared/SectionWrapper/SectionWrapper';
import { IconChat } from '../../icons/IconChat';
import { IconDeal } from '../../icons/IconDeal';
import { IconLike } from '../../icons/IconLike';
import { IconMoney } from '../../icons/IconMoney';
import { IconSequre } from '../../icons/IconSequre';
import "./SectionPluses.scss";

export function SectionPluses() {
  return (
    <SectionWrapper background="transparent">

      <h3 className="heading-1" style={{ textAlign: "center", maxWidth: "100%" }}>Наши преимущества</h3>

      <div className="SectionPluses-cards">
        <div className="SectionPluses-card">
          <IconChat />
          <p className="heading-mini">Поддержка 24/7</p>
          <p className="text-1">Наша команда всегда на&nbsp;связи и&nbsp;готова оказать помощь в&nbsp;любое время суток</p>
        </div>

        <div className="SectionPluses-card">
          <IconDeal />
          <p className="heading-mini">Присоединяйтесь к&nbsp;нашей реферальной программе!</p>
          <p className="text-1">
            Приглашайте своих друзей и&nbsp;коллег воспользоваться услугами в&nbsp;AG&ndash;Trade, пусть они напишут что пришли от&nbsp;Вас и&nbsp;получайте денежные бонусы на&nbsp;будущие сделки.
          </p>
        </div>

        <div className="SectionPluses-card">
          <IconLike />
          <p className="heading-mini">Лучшее предложение</p>
          <p className="text-1">
            Если вы&nbsp;найдете более выгодное предложение, отправьте его нам, и&nbsp;мы&nbsp;предложим вам более привлекательные условия
          </p>
        </div>
      </div>

      <div className="SectionPluses-cards">
        <div className="SectionPluses-card">
          <IconMoney />
          <p className="heading-mini">Отличные ставки</p>
          <p className="text-1">Мы&nbsp;тщательно проверяем самые выгодные предложения по&nbsp;финансовым услугам на&nbsp;рынке</p>
        </div>

        <div className="SectionPluses-card">
          <IconSequre />
          <p className="heading-mini">Надежный партнер</p>
          <p className="text-1">
            За&nbsp;12&nbsp;лет работы в&nbsp;финансовых услугах мы&nbsp;собрали более 1000 положительных отзывов и&nbsp;завоевали доверие множества клиентов
          </p>
        </div>
      </div>

    </SectionWrapper>
  );
}

export default SectionPluses;
