import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { IconWhatsapp } from '../../../icons/IconWhatsapp';
import "./NavItems.scss";
import { IconTelegram } from '../../../icons/IconTelegram';

type TNavItem = {
  text: string | ReactNode;
  link: string;
}

const items = [
  {
    text: "Главная",
    link: "/",
  },
  {
    text: "Обмен валют",
    link: "/change",
  },
  {
    text: "Криптовалюта",
    link: "/crypto",
  },
  {
    text: "Новости",
    link: "/news",
  },
  {
    text: "Контакты",
    link: "/contacts",
  },
  {
    text: <div className="NavItem-phone">+7 (999) 335-80-00 <IconWhatsapp /></div>,
    link: "https://wa.me/79993358000",
  },
];

const contactsLinks = [
  {
    text: <div className="NavItem-phone">Telegram <IconTelegram /></div>,
    link: "https://t.me/xAGxT",
  },
  {
    link: "mailto:ag-trade8@mail.ru",
    text: "ag-trade8@mail.ru",
  },
];

interface IProps {
  addedLinks?: TNavItem[];
  withContacts?: boolean;
}

export default function NavItems({ addedLinks, withContacts }: IProps) {
  let fullItems: TNavItem[] = [...items];

  if (addedLinks?.length) {
    fullItems = [...fullItems, ...addedLinks];
  }

  if (withContacts) {
    fullItems = [...fullItems, ...contactsLinks]
  }

  return <>
    {fullItems.map(item => (
      <Link key={item.link} className="NavItem text-1" to={item.link}>{item.text}</Link>
    ))}
  </>
}