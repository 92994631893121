import { ReactNode } from 'react';
import Banner from '../components/shared/Banner/Banner';
import Container from '../components/shared/Container/Container';
import { useNavigate } from 'react-router-dom';

import "./NewsPageTemplate.scss";

interface IProps {
  text: ReactNode;
  title: ReactNode | string;
  imageSrc?: string;
}

function NewsPageTemplate({ text, title, imageSrc }: IProps) {
  const navigate = useNavigate();

  return (
    <>
      <Banner customTitle=" " />
      <Container>
        <div className="NewsPageTemplate-back-button" onClick={() => navigate("/")}>
          <svg width="32px" height="auto" viewBox="0 0 32 32" version="1.1">
            <g id="icomoon-ignore"></g>
            <path d="M14.389 7.956v4.374l1.056 0.010c7.335 0.071 11.466 3.333 12.543 9.944-4.029-4.661-8.675-4.663-12.532-4.664h-1.067v4.337l-9.884-7.001 9.884-7zM15.456 5.893l-12.795 9.063 12.795 9.063v-5.332c5.121 0.002 9.869 0.26 13.884 7.42 0-4.547-0.751-14.706-13.884-14.833v-5.381z" fill="none" stroke="#aaaaaa" stroke-width="2">
            </path>
          </svg>
          На главную
        </div>
        <div className="NewsPageTemplate-card">
          <div>
            <div className="heading-1">{title}</div>
            <p className="NewsPageTemplate-text text-1">{text}</p>
          </div>
          {imageSrc && <img className="NewsPageTemplate-card-image" src={imageSrc} alt="" />}
        </div>
      </Container>
    </>
  );
}

export default NewsPageTemplate;
