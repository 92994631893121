import { useState } from 'react';
import ButtonAccent from '../../shared/ButtonAccent/ButtonAccent';
import Input, { InputPhone } from '../../shared/Input/Input';
import ModalWrapper from '../../shared/ModalWrapper/ModalWrapper';
import type { MaskEventHandler } from '@react-input/mask';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


import "./ModalRequest.scss";

function ModalRequest({ onClose }: { onClose: VoidFunction }) {
  const [phone, setPhone] = useState('');
  const form = useRef(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleMask: MaskEventHandler = (event) => {
    const cleanValue = cleanPhoneNumber(event.detail.value);
    setPhone(cleanValue);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!form.current) return;

    setLoading(true);

    setSuccessMessage("Отправляем данные...")

    // return console.log(form.current);

    emailjs.sendForm('service_ag_trade', 'template_5r2mp28', form.current, {
      publicKey: 'uWrvoY8ZWXv7j-g9-',
    }).then(
      () => {
        setLoading(false);
        setSuccessMessage("Заявка принята!");

        return setTimeout(() => {
          setSuccessMessage("");
          onClose();
        }, 2000);
      },
      () => {
        setLoading(false);
        setErrorMessage("Ошибка при отправке формы");

        return setTimeout(() => setErrorMessage(""), 2000);
      },
    );
  }

  return (
    <ModalWrapper maxWidth="500px" onClose={onClose}>
      <form ref={form} className="ModalRequest" onSubmit={onSubmit}>
        <Input type="text" name="user_name" placeholder="Ваше имя" />
        <InputPhone name="user_phone" handleMask={handleMask} />

        <ButtonAccent disabled={isLoading} type="submit">Отправить</ButtonAccent>
      </form>
      {successMessage && <p style={{ paddingTop: 8, color: "#4BB543" }}>{successMessage}</p>}
      {errorMessage && <p style={{ paddingTop: 8, color: "#ff3737" }}>{errorMessage}</p>}
    </ModalWrapper>
  );
}

function cleanPhoneNumber(phone: string) {
  return phone.replace(/\D/g, '');
}

export default ModalRequest;
