import { useEffect, useState } from 'react';

interface DataTableEntry {
  currency: string;
  buyRate: number;
  sellRate: number;
  minAmount: number;
}

interface DataTableCryptoEntry {
  currency: string;
  buyRate: number;
  sellRate: number;
  minAmount: number;
}

interface DataCardEntry {
  price: string; // Если цена может быть числом, можно использовать number вместо string
  name: string;
}

export interface IFetchData {
  data_table_main: DataTableEntry[];
  data_table_crypto: DataTableCryptoEntry[];
  data_cards_main: DataCardEntry[];
}

const fetchData = async () => {
  const response = await fetch('https://ag-trade.info/content.json');

  const jsonData = await response.json();

  let result: IFetchData = jsonData as IFetchData;

  if (!result) return undefined;
  if (!result.data_cards_main) return undefined;
  if (!result.data_table_crypto) return undefined;
  if (!result.data_cards_main) return undefined;

  return result;
}

export default fetchData;