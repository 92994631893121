import SectionWrapper from '../../components/shared/SectionWrapper/SectionWrapper';
import { IconClient } from '../../icons/IconClient';
import { IconShield } from '../../icons/IconShield';
import { IconStatusUp } from '../../icons/IconStatusUp';
import "./SectionActions.scss";

export function SectionActions() {
  return (
    <SectionWrapper background="transparent">
      <h3 className="heading-1 SectionActions-title">
        Зарабатывай вместе с&nbsp;AG&ndash;Trade!<br />
        Присоединяйся к&nbsp;нашей партнерской программе и&nbsp;получай комиссионные с&nbsp;каждой сделки
      </h3>
      <div className="SectionActions-cards">
        <div className="SectionActions-card">
          <IconClient />
          <h5 className="heading-mini">Приведи своего клиента</h5>
          <p className="text-1">Приведи своего клиента, кто хочет обменять валюту или провести другую финансовую операцию.</p>
        </div>

        <div className="SectionActions-card">
          <IconShield />
          <h5 className="heading-mini">Наше предложение</h5>
          <p className="text-1">Предлагаем эксклюзивное предложение, на&nbsp;которое вы&nbsp;можете добавить свой процент (дельту) для своего клиента.</p>
        </div>

        <div className="SectionActions-card">
          <IconStatusUp />
          <h5 className="heading-mini">Получение прибыли</h5>
          <p className="text-1">
            Вы&nbsp;можете получить разницу (дельту) в&nbsp;кассе после завершения сделки или&nbsp;же перевести на&nbsp;карту любого банка.
          </p>
        </div>
      </div>
    </SectionWrapper>
  );
}

export default SectionActions;
