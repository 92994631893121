import SectionWrapper from '../SectionWrapper/SectionWrapper';
import srcLogo from '../../../images/logo.jpg';
import "./Banner.scss";

interface IProps {
  customTitle?: string;
}

export function Banner({ customTitle }: IProps) {
  return (
    <SectionWrapper background="transparent">
      <div className="Banner-content">
        <div className="Banner-logo">
          <img src={srcLogo} alt="AG-Trage логотип" className="Banner-logo-image" />
          AG<span>-Trade</span>
        </div>

        <h1 className="heading-1 Banner-title">
          {customTitle ? customTitle : (
            <div className="Banner-title-content">
              Финансовые операции
              <br />
              <span>Профессионально</span>, <span>надежно</span> и <span>выгодно</span> выполним любую услугу для вас
            </div>
          )}
        </h1>
      </div>
    </SectionWrapper>
  );
}

export default Banner;
