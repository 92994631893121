

export function IconTelegram() {
      return (
            //     <svg height="32px" viewBox="0 0 512 512" style={{ fill: "#47b0d3" }}>
            //       <circle style={{ fill: "47B0D3" }} cx="256" cy="256" r="256" />
            //       <path style={{ fill: "#3298BA" }} d="M34.133,256c0-135.648,105.508-246.636,238.933-255.421C267.424,0.208,261.737,0,256,0
            // 	C114.615,0,0,114.615,0,256s114.615,256,256,256c5.737,0,11.424-0.208,17.067-0.579C139.642,502.636,34.133,391.648,34.133,256z"/>
            //       <path style={{ fill: "#E5E5E5" }} d="M380.263,109.054c-2.486-1.69-5.676-1.946-8.399-0.679L96.777,236.433
            // 	c-4.833,2.251-7.887,7.172-7.766,12.501c0.117,5.226,3.28,9.92,8.065,12.015l253.613,110.457c8.468,3.849,18.439-2.21,18.983-11.453
            // 	l14.314-243.341C384.161,113.614,382.748,110.742,380.263,109.054z"/>
            //       <polygon style={{ fill: "#CCCCCC" }} points="171.631,293.421 188.772,408 379.168,108.432 " />
            //       <path style={{ fill: "#FFFFFF" }} d="M371.866,108.375L96.777,236.433c-4.737,2.205-7.826,7.121-7.769,12.345
            // 	c0.058,5.233,3.276,10.074,8.067,12.171l74.557,32.471l207.536-184.988C376.882,107.33,374.203,107.287,371.866,108.375z"/>
            //       <polygon style={{ fill: "#E5E5E5" }} points="211.418,310.749 188.772,408 379.168,108.432 " />
            //       <path style={{ fill: "#FFFFFF" }} d="M380.263,109.054c-0.351-0.239-0.72-0.442-1.095-0.622l-167.75,202.317l139.27,60.657
            // 	c8.468,3.849,18.439-2.21,18.983-11.453l14.314-243.341C384.161,113.614,382.748,110.742,380.263,109.054z"/>
            //     </svg>
            <svg width="24px" viewBox="0 0 512 512">
                  <rect width="512" height="512" rx="15%" fill="#37aee2" />
                  <path fill="#c8daea" d="M199 404c-11 0-10-4-13-14l-32-105 245-144" />
                  <path fill="#a9c9dd" d="M199 404c7 0 11-4 16-8l45-43-56-34" />
                  <path fill="#f6fbfe" d="M204 319l135 99c14 9 26 4 30-14l55-258c5-22-9-32-24-25L79 245c-21 8-21 21-4 26l83 26 190-121c9-5 17-3 11 4" />
            </svg>
      );
}