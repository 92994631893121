import SectionTable from '../components-for-pages/SectionTable/SectionTable';
import Banner from '../components/shared/Banner/Banner';

function ChangePage() {
  return (
    <>
      <Banner customTitle="Обмен валюты" />
      <SectionTable
        isCrypto={false}
        title="Курсы валют от AG&ndash;Trade"
        subtitle="Покупка и продажа осуществляется по вашему запросу. Все операции обмена проводятся исключительно в кассе."
      />
      {/* <SectionChange /> */}
    </>
  );
}

export default ChangePage;
